<script>
  /**
   * A centered container with left and right padding, optionally with a maximum width.
   */
  export default {
    props: {
      /** The maximum width of the container. */
      maxWidth: {
        type: String,
        default: 'none',
        validator: (value) => ['none', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'].includes(value),
      },
      /** Reverts the container’s padding by applying negative margin of the same amount instead. Should only be used in a `BaseContainer` that has a `BaseContainer` ancestor, to “escape” from it. */
      revert: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      maxWidthClass() {
        switch (this.maxWidth) {
          case 'xs':
            return 'max-w-80'
          case 'sm':
            return 'max-w-120'
          case 'md':
            return 'max-w-180'
          case 'lg':
            return 'max-w-240'
          case 'xl':
            return 'max-w-280'
          case '2xl':
            return 'max-w-320'
          case '3xl':
            return 'max-w-360'
          default:
            return ''
        }
      },
    },
  }
</script>

<template>
  <div :class="`${revert ? '-mx-6 sm:-mx-9' : 'px-6 sm:px-9'}`">
    <div v-if="maxWidthClass" :class="`mx-auto ${maxWidthClass}`">
      <!-- @slot The container’s content. -->
      <slot />
    </div>
    <slot v-else />
  </div>
</template>
